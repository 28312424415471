import { walletConnect } from '@wagmi/connectors'
import { injected } from '@wagmi/core'
import type { Config } from 'wagmi'
import { createConfig, http } from 'wagmi'
import { arbitrum, base, bsc, mainnet, polygon } from 'wagmi/chains'

import { getEnvironment } from '~/environment'

let wagmiConfig: Config

const rpcUrl = (network: string) =>
  `https://lb.drpc.org/ogrpc?network=${network}&dkey=Ao9Zbso2EkxvmK69oOh35zRU689HXtoR75IAWq9OWu41`

/*
 * This is used in `root.tsx` to initialize the wagmi config
 * @see https://wagmi.sh/react/api/createConfig & `root.tsx`
 *
 * Initialized once, because of Remix modules that can not have side effects
 * @see https://remix.run/docs/en/main/guides/constraints#no-module-side-effects
 */
export function initializeWagmiConfig() {
  const config = createConfig({
    /**
     * should support all Infinity reward withdrawals chains
     * @see `cli.config.ts` => `withdrawalFee`
     */
    chains: [bsc, mainnet, base, polygon, arbitrum],
    transports: {
      [bsc.id]: http(rpcUrl('bsc')),
      [mainnet.id]: http(rpcUrl('ethereum')),
      [base.id]: http(rpcUrl('base')),
      [polygon.id]: http(rpcUrl('polygon')),
      [arbitrum.id]: http(rpcUrl('arbitrum')),
    },
    connectors: [
      injected(),
      walletConnect({
        projectId: getEnvironment().WALLETCONNECT_PROJECT_ID!,
        qrModalOptions: {
          themeMode: 'dark',
          themeVariables: {
            '--wcm-z-index': '1000',
          },
        },
      }),
    ],
    ssr: true,
  })

  wagmiConfig = config

  return config
}

export type WagmiConfig = typeof wagmiConfig

/**
 * @returns the wagmi config initialized in `root.tsx`
 */
export const getWagmiConfig = () => wagmiConfig

export type ConfiguredChainId = (typeof wagmiConfig.chains)[number]['id']

declare module 'wagmi' {
  interface Register {
    config: typeof wagmiConfig
  }
}
